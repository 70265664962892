@use "./reset";

@use "./../abstracts/spacing";
@use "./../abstracts/typography";

:root {
	--surface: #fff;
	--on-surface: #111;
	--highlight: rgb(0, 110, 255);
	--on-highlight: #fff;

	--card-color-1: #0070ff;
	--card-color-2: #00a200;
	--card-color-3: #ffe300;
	--card-color-4: #e00;
}

body {
	overflow-x: hidden;
}

// shame
button {
	background: none;
	border: 0;
	border-radius: 0;
	cursor: pointer;
	display: inline-block;
	font: inherit;
	padding: 0;
	margin: 0;
	text-align: inherit;
}

.swal-button {
	background: var(--highlight);
}

.swal-content__input:focus, .swal-content__textarea:focus {
	outline: none;
	border-color: var(--highlight);
}

input {
	display: block;
	max-width: 100%;
	width: 100%;
}
