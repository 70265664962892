@use "./typography-sizes.variables" as typo;

:root {
	--typo-size-100: #{typo.$size-100};
	--typo-line-height-100: 1.5;
	--typo-size-200: #{typo.$size-200};
	--typo-line-height-200: 1.5;
	--typo-size-300: #{typo.$size-300};
	--typo-line-height-300: 1.5;
	--typo-size-400: #{typo.$size-400};
	--typo-line-height-400: 1.45;
	--typo-size-500: #{typo.$size-500};
	--typo-line-height-500: 1.4;
	--typo-size-600: #{typo.$size-500};
	--typo-line-height-600: 1.35;
	--typo-size-700: #{typo.$size-500};
	--typo-line-height-700: 1.3;
	--typo-size-800: #{typo.$size-500};
	--typo-line-height-800: 1.25;
	--typo-size-900: #{typo.$size-500};
	--typo-line-height-900: 1.2;
}

body {
	font-size: var(--typo-size-300);
	line-height: var(--typo-line-height-300);
}
